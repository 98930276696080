import React from "react"
import { Link } from "gatsby"
import * as Icon from "react-feather"

import tsLogo from "../assets/images/logo-ts.png"
import Call from "../assets/images/call.png"

const NavbarNew = () => {
  const [menu, setMenu] = React.useState(true)

  const toggleNavbar = () => {
    setMenu(!menu)
  }

  React.useEffect(() => {
    // let elementId = document.getElementById("header")
    // document.addEventListener("scroll", () => {
    //   if (window.scrollY > 170) {
    //     elementId.classList.add("is-sticky")
    //   } else {
    //     elementId.classList.remove("is-sticky")
    //   }
    // })
    // window.scrollTo(0, 0)
  })

  function isActive({ isPartiallyCurrent }: any) {
    return isPartiallyCurrent ? { className: "active nav-link" } : {}
  }

  const classOne = menu
    ? "collapse navbar-collapse"
    : "collapse navbar-collapse show"
  const classTwo = menu
    ? "navbar-toggler navbar-toggler-right collapsed"
    : "navbar-toggler navbar-toggler-right"

  return (
    <header id="header" className="headroom">
      <div className="startp-nav">
        <div className="container">
          <nav className="navbar navbar-expand-lg navbar-light">
            <Link to="/" onClick={toggleNavbar} className="navbar-brand">
              <img src={tsLogo} alt="logo" />
            </Link>

            <button
              onClick={toggleNavbar}
              className={classTwo}
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="icon-bar top-bar"></span>
              <span className="icon-bar middle-bar"></span>
              <span className="icon-bar bottom-bar"></span>
            </button>

            <div className={classOne} id="navbarSupportedContent">
              <ul className="navbar-nav ms-auto align-items-center">
                <li className="nav-item">
                  <Link to="/" activeClassName="active" className="nav-link">
                    Home
                  </Link>
                </li>

                <li className="nav-item">
                  <Link
                    to="/services"
                    activeClassName="active"
                    // onClick={e => e.preventDefault()}
                    className="nav-link"
                    getProps={isActive}
                  >
                    Services <Icon.ChevronDown />
                  </Link>
                  <ul className="dropdown-menu">
                    <li className="nav-item">
                      <Link
                        to="/services/mobile-app-development/"
                        className="nav-link"
                        activeClassName="active"
                        getProps={isActive}
                      >
                        Mobile App Development
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link
                        to="/services/web-app-development/"
                        className="nav-link"
                        activeClassName="active"
                        getProps={isActive}
                      >
                        Web App Development
                      </Link>
                    </li>
                    {/* <li>
                      <Link
                        to="/services/cross-platform-app-development/"
                        className="nav-link"
                        activeClassName="active"
                        getProps={isActive}
                      >
                        Cross Platform Development
                      </Link>
                    </li> */}
                  </ul>
                </li>

                <li className="nav-item">
                  <Link
                    to="/our-work"
                    className="nav-link"
                    activeClassName="active"
                    getProps={isActive}
                  >
                    Our Work
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/our-recipe"
                    className="nav-link"
                    activeClassName="active"
                    getProps={isActive}
                  >
                    Our Recipe
                  </Link>
                </li>

                <li className="nav-item">
                  <Link
                    to="/blogs"
                    className="nav-link"
                    activeClassName="active"
                  >
                    Blogs
                  </Link>
                </li>

                <li className="nav-item">
                  <Link
                    to="#get-in-touch"
                    className="nav-link"
                    activeClassName="active"
                  >
                    Contact Us
                  </Link>
                </li>

                <li className="nav-item">
                  <a className=" nav-link active" href="tel:7738811194">
                    <img src={Call} alt="logo" height={25} /> +91-7738811194
                  </a>
                </li>
              </ul>
            </div>

            {/* <div className="others-option">
              <Link to="/cart" className="cart-wrapper-btn">
                <Icon.ShoppingCart />
                <span>0</span>
              </Link>

              <Link to="/contact" className="btn btn-light">
                Support
              </Link>

              <Link to="/login" className="btn btn-primary">
                Login
              </Link>
            </div> */}
          </nav>
        </div>
      </div>
    </header>
  )
}

export default NavbarNew
