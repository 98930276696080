/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql, withPrefix } from "gatsby"

function SEO({
  description,
  ogImage,
  lang,
  meta,
  title,
  ogTwitterCardType,
  keywords,
  url,
  twitterImage,
}) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            ogImage
            description
            author
            siteUrl
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const metaOgImage = ogImage || site.siteMetadata.ogImage
  const defaultTitle = site?.siteMetadata?.title
  const metaUrl = site?.siteMetadata?.siteUrl + url

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
      meta={[
        {
          property: `title`,
          content: title,
        },
        {
          name: `description`,
          content: metaDescription,
          lang: `en`,
        },
        {
          property: `og:site_name`,
          content: title,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: metaOgImage,
        },
        {
          property: `og:url`,
          content: metaUrl,
        },
        {
          property: `og:latitude`,
          content: `19.0145344`,
        },
        {
          property: `og:longitude`,
          content: `73.0973396`,
        },
        {
          property: `og:street-address`,
          content: `Shop 1, Sai Pooja Society Ltd, Sector 21, Kamothe, Navi Mumbai, Maharashtra 410209`,
        },
        {
          property: `og:locality`,
          content: `Navi Mumbai, India`,
        },
        {
          property: `og:postal-code`,
          content: `410209`,
        },
        {
          property: `og:country-name`,
          content: `India`,
        },
        {
          property: `og:email`,
          content: `sumit.chakole@tarkashilpa.com`,
        },
        {
          property: `og:phone_number`,
          content: `+91-7738811194`,
        },
        {
          name: `geo.region`,
          content: `MH`,
        },
        {
          name: `twitter:card`,
          content: ogTwitterCardType,
        },
        // {
        //   name: `twitter:creator`,
        //   content: site.siteMetadata?.author || ``,
        // },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `twitter:image:src`,
          content: twitterImage,
        },
        {
          name: `twitter:site`,
          content: `@tarkashilpa`,
        },
        {
          name: `facebook-domain-verification`,
          content: "4njqfiqjcrla09ae31c0obnf0y47v4",
        },
        {
          name: `keywords`,
          content: keywords,
          lang: `en`,
        },
        {
          name: `robots`,
          content: `index,follow`,
          lang: `en`,
        },
        {
          name: `App Development`,
          content: metaUrl,
        },
        {
          name: `format-detection`,
          content: `telephone=yes`,
        },
        {
          name: `viewport`,
          content: `width=device-width, height=device-height, minimum-scale=1.0, initial-scale=1.0, user-scalable=0`,
        },
      ].concat(meta)}
    ></Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
  ogImage: ``,
  ogTwitterCardType: `summary`,
}

SEO.propTypes = {
  description: PropTypes.string,
  ogImage: PropTypes.string,
  ogTwitterCardType: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
