import React from "react"
import GoTop from "./GoTop"
import { useEffect } from "react"

const Layout = ({ children }) => {
  useEffect(() => {
    const hash = window.location.hash
    if (hash) {
      const idName = hash?.substring(1, hash.length)

      setTimeout(() => {
        var elem = document.getElementById(`${idName}`)
        // console.log("elem", elem)
        // console.log("elem.offsetTop", elem.offsetTop)

        elem.scrollTop = 10
      }, 100)
    }
  }, [])
  return (
    <>
      <script
        type="text/javascript"
        id="google-tag-manager"
        dangerouslySetInnerHTML={{
          __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-NFFX325');`,
        }}
      />
      {/*  Google Tag Manager (noscript) */}
      {/* <noscript>
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-NFFX325"
          height="0"
          width="0"
          style="display:none;visibility:hidden"
        ></iframe>
      </noscript> */}
      {/*  End Google Tag Manager (noscript)  */}
      {children}
      <GoTop scrollStepInPx="100" delayInMs="10.50" />
    </>
  )
}
export default Layout
